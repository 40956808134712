<template>
    <div>
        <navbar/> 
        <actionneurs-capteurs-image/>
        <actionneurs-capteurs-presentation class="padding-resp"/>
        <actionneurs-capteurs-liste class="padding-resp mt100"/>
        <actionneurs-capteurs-fonctionnement class="padding-resp mt100"/>
        <bulle-contact/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
import navbar from '@/components/NavBar.vue'
import ActionneursCapteursImage from '../components/ActionneursCapteursImage.vue';
import ActionneursCapteursListe from '../components/ActionneursCapteursListe.vue';
import ActionneursCapteursPresentation from '../components/ActionneursCapteursPresentation.vue';
import ActionneursCapteursFonctionnement from '../components/ActionneursCapteursFonctionnement.vue';
import BulleContact from '../components/BulleContact.vue';
export default {
    components:{
      foot,
      navbar,
      ActionneursCapteursImage,
      ActionneursCapteursListe,
      ActionneursCapteursPresentation,
      ActionneursCapteursFonctionnement,
      BulleContact,
    },
    metaInfo: {
        title: 'MiceTracking - Actionneurs et Capteurs Intelligents pour le Bien-Être Animal',
        meta: [
            {
                name: 'description',
                content: 'Découvrez les actionneurs et capteurs MiceTracking pour un suivi précis et interactif des souris de laboratoire. Mesurez ammoniaque, la température, les mouvements, et bien plus pour garantir le bien-être de vos souris de laboratoire en laboratoire.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, actionneurs, capteurs, bien-être animal, suivi en temps réel, capteur d\'ammoniaque, capteur de température, capteur de mouvements, laboratoire, souris de laboratoire, interaction capteurs/animal'
            }
        ]
    },

    data() {
    return {
      fadeInText1: false,
      fadeInText2: false,
      fadeInText3: false,
      fadeInText4: false,
      fadeInImg1: false,
      fadeInImg2: false,
      fadeInImg3: false,
      fadeInDiv1: false,
      
      // ajoutez d'autres variables de données pour les autres textes (text-2, text-3, text-4) si nécessaire
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  

  methods: {
    
  },
};
</script>

<style scoped>
.img-actionneurs{
    background-image: url(../images/cage-de-laboratoire.jpg);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-position: 25% 70%;
    text-align: justify;
    position: relative;
}

.img-actionneurs::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
}

.text-actionneurs{
    position: relative;
    margin-top: 140px;
}

.text-actionneurs h1{
    width: 60%;
    margin-bottom: 20px;
}


.text-actionneurs p{
    width: 60%;
    margin-bottom: 40px;
}

.list-ico-a {
    margin-top: 240px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.list-ico-a div {
    text-align: center;
    position: relative;
}

.list-ico-a .ico-caption {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.list-ico-a .icon {
    max-width: 160px;
    height: auto;
    width: 100%;
    transition: transform 0.3s ease;
}

.list-ico-a .icon:hover {
    transform: rotateY(180deg);
}

.list-ico-a div:hover .ico-caption {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}



.démo{
    margin-top: 50px;
}

.démo img{
    width: 90%;
}

.plateformes{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.encart-plateforme{
    width: 23%;
    background-color: #ffffff;
    padding: 10px 10px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.encart-plateforme h3{
    text-align: center;
    margin-bottom: 10px;
    line-height: 25px;
}

.encart-plateforme p{
    font-family: Roboto;
    text-align: justify;
    list-style: '- ';
    color: var(--gris);
    font-size: 18px;
}

.title-fonctionnement{
    margin: 50px 0px;
    margin-bottom: 50px;
    text-align: left;
    width: 50%;
}

.actionneurs{
    display: flex;
    justify-content: space-between;
}

.actionneurs div{
    width: 30%; 
    text-align: justify;
    margin-top: 50px;
    margin-bottom: 50px;
}

.actionneurs2 div{
    width: 30%; 
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
}

.actionneurs div h3{
    font-family: Roboto, Bold;
    font-size: 35px;
    color: var(--bleu);
    margin-top: 25px;
}

.actionneurs div h4{
    font-family: Roboto, thin;
    font-size: 20px;
    color: var(--bleu);
}

.actionneurs div img{
    width: 100%; 
    border: solid 1px var(--bleu);
}
.actionneurs div p{
    margin-top: 25px;
    margin-bottom: 50px;
}

.capteurs{
    margin-top: 20px;
    margin-left: 80px;
}

.tablette{
    margin-top: 150px;
    margin-left: 100px;
    width: 100%;
}

.cage{
    margin-top: 240px;
    margin-left: -100px;
    width: 100%;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.écran{
    margin-top: 70px;
    margin-left: -80px;
    width: 120%;
}

.illu-placement-1{
    border-radius: 20px;
    margin-left: -20%;
    margin-top: 250px;
    width: 100%;
}

.illu-placement-2{
    margin-top: 150px;
    border-radius: 20px;
    width: 110%;
    border: solid 2px white;
}

.illu-placement-3{
    margin-top: 100px;
    border-radius: 20px;
    margin-left: -20%;
}

/* Schéma illustration */

.text-illu {
  display: flex;
  opacity: 0; /* Ajoutez cette règle pour initialiser l'opacité à 0 */
  transition: opacity 2s ease; /* Ajoutez une transition pour l'animation de fondu */
}
.fade-in {
  opacity: 1 !important;
}
.text-illu h3{
    width: 15%;
    font-size: 80px;
}
.text-illu p{
    padding-left: 4%;
    width: 80%;
    font-size: 20px;
}

.text-2{
    margin-top: 320px;
    margin-left: -20%;
}

.text-2 p{
    margin-top: 35px;
}

.text-1{
    margin-left: 20px;
    margin-top: 70px;
    width: 130%;
}

.text-1 p{
    margin-top: 15px;
}

.text-3{
    margin-left: 20px;
    margin-top: 380px;
    width: 130%;
}

.text-3 p{
    margin-top: 15px;
}

.text-4{
    margin-top: 380px;
    width: 130%;
    margin-left: -20%;
}
.text-4 p{
    margin-top: 10px;
}
 /* Fin schéma illustration */

 .capteurs-actionneurs{
    background-color: white;
    border-radius: 20px;
    box-shadow: 10px 6px 10px rgba(1, 37, 73, 0.33);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 115%;
    padding-right: 30px;
    margin-left: 80px;
    margin-top: -50px;
 }

 .title-wrapper {
    flex: 0 0 auto;
}

 .title-wrapper h4{
    margin: 20px 0px;
    color: var(--bleu);
    font-size: 20px;
    flex: 0 0 auto;
}

.list-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.ombre{
    box-shadow: 10px 6px 10px rgba(1, 37, 73, 0.33);
}

.capteurs-actionneurs ul li{
    display: flex; 
    align-items: center;
    
}

.capteurs-actionneurs ul li p{
    font-size: 16px;
    margin-top: 15px;
    width: 90%; 
    margin-left: 3%;
    text-align: justify;
}

.capteurs-actionneurs ul li img{
    width: 35px;
}


 .div-illu {
  opacity: 0; /* Ajoutez cette règle pour initialiser l'opacité à 0 */
  transition: opacity 2s ease; /* Ajoutez une transition pour l'animation de fondu */
}



#btn-2{
    border: solid 2px var(--bleu);
    background-color: var(--bleu);
    font-family: Roboto, Regular;
    font-size: 16px;
    color: white;
    padding: 8px 25px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    position: relative;
    text-decoration:none;
    border-radius: 25px;
}

#btn-2:hover{
    background-position: 100%;
    color: var(--bleu);
    background-color: transparent;
}

.analyse{
    text-align: center;
}

.analyse h1{
    margin-bottom: 50px;
}

.analyse p{
    width: 90%;
    margin-left: 5%;
    margin-top: 50px;
}

/* *******************************************
*************RESPONSIVE**********************
******************************************** */

@media (max-width: 1264px) {
  /* Styles for screens with a width less than or equal to 960 pixels */
  /* Add your CSS code specific to this breakpoint here */
.img-actionneurs{
    height: 80vh;
}

.text-actionneurs{
    text-align: justify;
    margin-top: 50px;
}
.text-actionneurs p{
    width: 80%;
}

.baton{
    display: none
}


.text-actionneurs h1{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.text-actionneurs p{
    width: 90%;
    text-align: justify;
}


.list-ico-a{
    width: 100%;
    margin-top: 40px;
    justify-content: space-around;
}
.list-ico-a .ico-caption {
    text-align: center;
    width: 200%;
    margin-left: -50%;
}
.list-ico-a div{
    width: 50px;
}

.position-resp h1{
    margin-bottom: 20px;
}

.démo{
    margin-top: 0px;
}

.démo img{
    width: 90%;
    margin-left: 5%;
}



.display-fonctionnement{
    
    display: none;
}


.encart-plateforme {
    margin-left: 15%;
    width: 70%; /* Modifier la largeur pour ajuster l'espacement */
    margin-bottom: 10px; /* Ajouter une marge inférieure pour l'espacement */
}

.plateformes{
    display: block;
}

.container img{
    width: 100%;
}

.rangement{
    display: block;
}

.actionneurs{
    display: block;
}

.actionneurs div{
    width: 100%;
    text-align: left;
}

.special-width{
    width: 100%;
}

}

@media (max-width: 767px) {
.text-actionneurs p {
    width: 100%;
    padding: 10% 5%;
}
.text-actionneurs h1{
    width: 100%;
}
.démo{
    margin-top: 0px;
}
.list-ico-a div{
    width: 35px;
}
}

</style>
