<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="5" xl="4" class="image-container">
        <img src="../images/matriceActionneursCapteurs.png" alt="">
      </v-col>
      <v-col cols="12" sm="10" lg="5" xl="5" class="detail">
        <h2>Les Actionneurs et Capteurs de MiceTracking</h2>
        <p>Les actionneurs et capteurs MiceTracking sont des équipements innovants spécialement conçus pour la recherche en laboratoire. Placés directement dans les cages et une fois connectés à la matrice de micetracking, ces dispositifs permettent de surveiller et d’interagir de manière automatisée avec les animaux, offrant une flexibilité accrues et de nouvelles possibilités dans la conduite des expériences scientifiques.</p>
        <div> 
          <details>
              <summary>Automatisation Intelligente</summary>
              <p>Réduisez les interventions manuelles et automatisez vos expériences pour gagner en temps et en précision, tout en minimisant les erreurs humaines.</p>
          </details>

          <details>
              <summary>Collecte Précise</summary>
              <p>Enregistrez des données en temps réel avec grande précision, garantissant des résultats fiables et reproductibles pour vos études scientifiques.</p>
          </details>
          <details>
              <summary>Personnalisation Flexible</summary>
              <p>Adaptez chaque expérience selon vos besoins spécifiques grâce à des séquences programmables, permettant de réaliser des tests sur mesure.</p>
          </details>
          <details>
              <summary>Bien-être Animal</summary>
              <p>Surveillez en continu l’état des animaux et assurez leur bien-être grâce à des capteurs qui suivent l’environnement sanitaire.</p>
          </details>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

.image-container {
  background-color: var(--bleuclair);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  height: 500px; /* Set a fixed height */
}

.image-container img {
  width: 90%;
  object-fit: contain; /* Ensures the image scales within the container */
}

.detail {
  padding-left: 50px;
  overflow: auto; /* Allows the content to scroll if it becomes too large */
}

.detail div {
  background-color: white;
  border-radius: 15px;
}

summary{
  height: 35px;
  padding: 7px 10px;
}
details {
    overflow: hidden;
    padding-left: 10px;
  }
details[open] p {
    animation: slideDown 0.3s ease-in-out;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@media (max-width: 960px) {

.detail{
  margin-top: 50px;
}
.detail p{
  margin-top: 15px;
}

.detail div{
  margin-top: 35px;
}

}
</style>

<script>
  export default {
		name: 'App',
		components: {
		},
	};
</script>